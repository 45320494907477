<template>
  <section class="body-sign">
    <div class="center-sign">
      <a href="/" class="logo float-left">
        <img src="/img/logo-short-color.png" height="40" alt="Estoque Auto" />
      </a>

      <div class="panel card-sign">
        <div class="card-title-sign mt-3 text-right">
          <h2 class="title text-uppercase font-weight-bold m-0"><i class="fas fa-user mr-1"></i> Recuperar senha</h2>
        </div>
        <div class="card-body">
          <div class="alert alert-info">
            <p class="m-0">Digite seu e-mail abaixo e iremos enviar as instruções para resetar sua senha!</p>
          </div>

          <form>
            <div class="form-group mb-0">
              <div class="input-group">
                <input name="username" v-model="email" type="email" placeholder="E-mail" class="form-control form-control-lg" />
                <span class="input-group-append">
                  <button class="btn btn-primary btn-lg" :disabled="loading" @click="enviar" type="button">Enviar email!</button>
                </span>
              </div>
            </div>

            <p class="text-center mt-3">Lembrou? <router-link to="/auth">Entre agora!</router-link></p>
          </form>
        </div>
      </div>

      <p class="text-center text-muted mt-3 mb-3">&copy; Estoque Auto. Todos os direitos reservados.</p>
    </div>
  </section>
</template>

<script>
  // @ is an alias to /src
  import { emailRecovery } from '@/services/user.service.js';

  export default {
    data() {
      return {
        email: '',
        error: '',
        loading: false
      };
    },
    mounted: function () {
      let that = this;
      this.$nextTick(function () {
        that.email = that.$route.params.email;
      });
    },
    methods: {
      enviar: function () {
        debugger;
        this.loading = true;
        emailRecovery(this.email)
          .then(response => {
            const { msg, success } = response.data;
            alert(msg);
            if (success) {
              this.$router.push('/');
            }
            else {
              this.loading = false;
            }

          });
      }
    },
  }

</script>
<style scoped>

  .logo {
    margin-top: 1.7rem
  }
</style>
